<template>
  <Toast :msg="toastMsg" />

  <nav class="navbar navbar-expand-lg bg-light" :class="{ 'fixed-top': mode == 0, 'sticky-top': mode == 1 }">
    <div class="container-fluid">
      <router-link to="/" class="nav-link" aria-current="page">Gamefish</router-link>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Games
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <button class="dropdown-item" @click="showGames('FL')">Game code: FL.I</button>
              <button class="dropdown-item" @click="showGames('SL')">Game code: SL.II</button>
              <li>
                <hr class="dropdown-divider">
              </li>
              <button class="dropdown-item" @click="showGames('UN')">Game code: UN.III</button>
              <!-- <li><a class="dropdown-item" href="#">Game I</a></li>
              <li><a class="dropdown-item" href="#">Game II</a></li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li><a class="dropdown-item" href="#">Game III</a></li> -->
            </ul>
          </li>

          <li class="nav-item">
            <router-link to="/about" class="nav-link">About</router-link>
          </li>
        </ul>
        <!-- <form class="d-flex" role="search">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form> -->
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    mode: {
      type: Number,
      required: false,
      default: 0
    },
  },

  data() {
    return {
      toastMsg: ''
    };
  },

  methods: {
    showGames(gameName) {
      this.toastMsg = `The game of code ${gameName} is under development.`;
    }
  }
}
</script>

<style scoped>
</style>
