<template>
  <div class="toast-container position-fixed bottom-0 end-0 p-3">
    <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <strong class="me-auto">{{ title }}</strong>
        <small>{{ now() }}</small>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">
        {{ msg }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: 'Info'
    },
    msg: {
      type: String,
      required: true,
      default: 'null'
    },
  },

  data() {
    return {
      toast: null
    }
  },

  mounted() {
    const elem_liveToast = document.getElementById('liveToast');
    this.toast = new bootstrap.Toast(elem_liveToast);
  },

  watch: {
    msg(newMsg, oldMsg) {
      this.toast.show();
    }
  },

  methods: {
    now() {
      return new Date().toLocaleString('UTC');
    }
  }
}
</script>
