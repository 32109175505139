<template>
  <div id="app-content">
    <Banner :hidden="!showBanner" />
    <Navbar :mode="navMode" />

    <router-view class="router-view" v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </div>
  <div id="app-footer" class="fixed-bottom">
    <p>
      powered by
      <a href="https://lishiyu.com/" target="_blank" rel="noopener"><b>lishiyu.com</b></a>
    </p>
  </div>
</template>

<script>
import Banner from './components/Banner.vue'
import Navbar from './components/Navbar.vue'

export default {
  components: {
    Banner,
    Navbar
  },
  data() {
    return {
      showBanner: false,
    }
  },
  mounted() {
  },
  computed: {
    navMode() {
      return this.showBanner ? 1 : 0;
    }
  }
}
</script>

<style scoped>
#app-content {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 100px;
}
#app-footer {
  margin-left: 20px;
}
</style>

<style>
a {
  color: #42b983;
}
</style>
