import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import mixin from './mixins'
import globalComponents from './components'

const app = createApp({
  mixins: [mixin],
  ...App
})

// router
app.use(router)

// Components Global Registration
app.component('Toast', globalComponents.Toast)

//#region bootstrap

// import { BootstrapVue3 } from 'bootstrap-vue-3'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

// app.use(BootstrapVue3)

//#endregion //bootstrap

app.mount('#app')
