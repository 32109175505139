<template>
  <div id="banner" class="carousel carousel-fade" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active" v-bind:data-bs-interval="bannnerInterval">
        <p class="d-block w-100">123</p>
      </div>
      <div class="carousel-item" v-bind:data-bs-interval="bannnerInterval">
        <p class="d-block w-100">321</p>
      </div>
      <div class="carousel-item" v-bind:data-bs-interval="bannnerInterval">
        <p class="d-block w-100">233</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bannnerInterval: 2000
    }
  }
}
</script>
